define("commander/helpers/c-translate", ["exports", "commander/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class2, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Helper for translating customer specific strings.
   * 1. Customer environment should define e.g. ENV.i18n.customerLocale=tuomi
   * 2. Translation file should have normal translations in json format.
   * 3. Overridden translations should go under customerLocale.[customer].[same path as normal translation]
   *
   * Example, override for tuomi:
   * - localization: tripDetails.excessPrice exists
   * - localization should be added: customerLocale.tuomi.tripDetails.excessPrice
   * - call with: {{c-translate "tripDetails.excessPrice"}}
   */
  let _class = (_dec = Ember.inject.service, (_class2 = (_temp = class _class2 extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "i18n", _descriptor, this);
    }

    init() {
      super.init(...arguments);
    }

    compute([loc]) {
      const customer = _environment.default.i18n.customerLocale;
      let text = '';

      if (customer && customer !== 'default') {
        text = this.i18n.t(`customerLocale.${customer}.${loc}`, {
          default: loc
        });
      } else {
        text = this.i18n.t(loc);
      }

      return text;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});